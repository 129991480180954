<template>
    <ValidationObserver rules="required">
      <RightSideBar v-if="isOpen" @close="isOpen=false, $emit('close')" button-class="bg-dynamicBackBtn text-white" :close-button="true" close-button-classes="bg-dynamicBackBtn text-white w-auto radius" :loading="isLoading">
        <template v-slot:title>
          <p class="text-darkPurple">
            {{ title }}
          </p>
        </template>
        <div class="flex flex-col ">
          <div class="grid grid-cols-7 gap-5 w-full justify-between">
            <div class="col-span-2  w-full h-full" v-if="userData.photo">
              <img :src="userData.photo" alt="not-found" class="h-full w-full">
            </div>
            <div v-else class="col-span-2 rounded-md h-full w-full border flex justify-center items-center">
              <span class="text-center font-bold text-24 text-blueCrayola">
                {{ $getInitials(`${userData.fname} ${userData.lname}`) || '-' }}
              </span>
            </div>
            <div class="col-span-5 flex flex-col h-full justify-between">
              <div class="text-darkPurple font-bold text-18">{{ userData.fname || '-' }} {{ userData.lname || '-' }}</div>
              <div class="flex w-full justify-between">
                <div class="text-14 text-romanSilver">Function:</div>
                <div class="text-14 text-darkPurple font-bold" v-if="userData.orgFunction">{{ userData.orgFunction.name|| '-'}}</div>
                <div class="text-14 text-darkPurple font-bold" v-else>-</div>
              </div>
              <div class="flex w-full justify-between">
                <div class="text-14 text-romanSilver">Designation:</div>
                <div class="text-14 text-darkPurple font-bold" v-if="userData.userDesignation">{{ userData.userDesignation.name || '-'}}</div>
                <div class="text-14 text-darkPurple font-bold" v-else>-</div>
              </div>
              <div class="flex w-full justify-between">
                <div class="text-14 text-romanSilver">Job Level:</div>
                <div class="text-14 text-darkPurple font-bold" v-if="userData.userLevel">{{ userData.userLevel.name || '-'}}</div>
                <div class="text-14 text-darkPurple font-bold" v-else>-</div>
              </div>
              <div class="flex w-full justify-between">
                <div class="text-14 text-romanSilver">Office Location:</div>
                <div class="text-14 text-darkPurple font-bold" v-if="userData.employeeOffice">{{ userData.employeeOffice.name || '-'}}</div>
                <div class="text-14 text-darkPurple font-bold" v-else>-</div>
              </div>
              <div class="flex w-full justify-between">
                <div class="text-14 text-romanSilver">Manager:</div>
                <div class="text-14 text-darkPurple font-bold" v-if="userData.userReportingTo">{{ userData.userReportingTo.fname }}  {{userData.userReportingTo.lname}}</div>
                <div class="text-14 text-darkPurple font-bold" v-else>-</div>
              </div>
              <div class="flex w-full justify-between">
                <div class="text-14 text-romanSilver">Length of Service:</div>
                <div class="text-14 text-darkPurple font-bold" v-if="userData.lengthOfService">{{userData.lengthOfService}}</div>
                <div class="text-14 text-darkPurple font-bold" v-else>-</div>
              </div>
            </div>
          </div>
          <div class=""></div>
        </div>

        <div v-if="approvers.length" class="mt-5">
          <div>
            <div class="flex flex-col justify-start gap-5">
              <div class="grid grid-cols-2 gap-2">
                <div v-for="(status, i) in statuses" :key="`${status}-${i}`">
                  <div
                    :class="approvalStatus === status ? 'r-active' : 'r-inactive'"
                    class="rounded-md p-3 flex justify-start items-center gap-2 w-full"
                  >
                    <Icon
                      icon-name="icon-display-grid"
                      class-name="text-romainSilver"
                      size="xs"
                    />
                    <p class="text-sm leading-5 capitalize">
                      {{ status.split("_").join(" ") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full flex flex-col mt-10 mb-20 gap-2">
          <div class="text-darkPurple font-bold text-18">IN PROGRESS</div>
          <div class="border-broken w-full"></div>
          <div class="flex flex-col mt-5 gap-2">
              <div class="w-full flex justify-between progress-box-item" v-for="approver in approvers" :key="approver.userId">
                <div class="flex gap-5px">
                  <div class="" v-if="approver.photo">
                    <img :src="approver.photo" class="rounded-md w-9 h-9">
                  </div>
                  <div v-else class="rounded-md w-9 h-9 border flex justify-center items-center">
                    <span class="text-center font-bold text-sm text-blueCrayola">
                      {{ $getInitials(`${approver.fname} ${approver.lname}`) || '-' }}
                    </span>
                  </div>
                  <div class="flex flex-col">
                    <div class="text-desire text-xs">Approved by</div>
                    <div class="text-sm text-darkPurple capitalize">{{ approver.fname || '-'}} {{ approver.lname || '-'}}</div>
                    <div class="text-xs text-romanSilver capitalize">{{ approver.userDesignation.name || '-'}}</div>
                  </div>
                </div>
                <div class="flex items-center text-accentSquash text-xs flex-col justify-center">
                    <p
                      class="font-normal text-xs capitalize"
                      :class="{
                        c_approved: approver.hasApproved === 'true',
                        c_disapproved: approver.hasApproved === 'false',
                        c_pending: approver.currentApprover === 'active',
                        c_inactive: approver.currentApprover === 'inactive',
                        c_cancelled: approver.currentApprover === 'cancelled',
                      }"
                    >
                      <span v-if="approver.currentApprover === 'active'">Pending</span>
                      <span v-if="approver.currentApprover === 'cancelled'">Cancelled</span>
                      <span v-if="approver.currentApprover === 'inactive'">Not Started</span>
                      <template v-if="approver.currentApprover === 'hasApproved'">
                        <span v-if="approver.hasApproved === 'true'" class="text-mediumSeaGreen">Approved</span>
                        <span v-else class="text-desire">Disapproved</span>
                      </template>
                    </p>
                    <template v-if="approver.currentApprover === 'hasApproved'">
                      <p class="font-semibold text-xs uppercase text-jet">
                        {{ $DATEFORMAT( new Date(approver.actionTakenAt), "MMMM dd, yyyy") || '-'}}
                      </p>
                    </template>
                  </div>
              </div>
          </div>
        </div>
      </RightSideBar>
    </ValidationObserver>
  </template>

  <script>
  import _ from 'lodash';
  import { ValidationObserver } from "vee-validate";
  import RightSideBar from "@/components/RightSideBar";

  export default {
    name: "ApprovalStatus",
    components: {
      ValidationObserver,
      RightSideBar
    },
    props: {
      openModal: {
        type: Boolean,
        default: false
      },
      userData: {
        type: Object,
        default: () => ({})
      },
      title: {
        type: String,
        default: 'Approval Status'
      },

    },
    data() {
      return {
        statuses: [
          "not_started",
          "in_progress",
          "approved",
          "disapproved"
        ],
        approvers: [],
        approvalStatus: '',
        isLoading: true,
        isOpen: true,
      };
    },
    methods: {
      getApprovalStatus(arr) {
        if (_.every(arr, ['hasApproved', 'true'])) {
          return 'approved';
        }
        if (_.some(arr, ['hasApproved', 'false'])) {
          return 'disapproved'
        }
        if (_.some(arr, ['hasApproved', null])) {
          if (_.some(arr, ['currentApprover', 'active'])) {
            return 'in_progress'
          } if (_.every(arr, ['currentApprover', 'inactive'])) {
            return 'not_started'
          }
        }
        return ''
      },
      async getData() {
        const id = this.userData.suspension ? this.userData.suspension.id : this.userData.disengageStat.id || "";
        const response = await this.$_getApprovalProgress(id || "");
        this.approvers = response.data.data;
        this.isLoading = false;
        this.approvalStatus = this.getApprovalStatus(response.data.data);
      },
    },
    async mounted() {
      await this.getData();
    }
  };
  </script>

  <style scoped>
  .rightSidebar {
    width: 520px !important;
  }
  .gap-5px{
    gap: 5px
  }
  .tab{
    padding: 12px;
    border-radius: 5px;
    background: #F6F8F9;
    color: #667499;
  }
  .tab.active{
    background: #FE4D3C;
    color: white;
  }
  .border-broken{
    border: 1px dashed #878E99;
  }
  .progress-box-item{
    box-shadow: 0px 1px 3px 0px #08070837;
    box-shadow: 0px 1px 2px 0px #2d31423e;
    padding: 12px;
    border-radius: 5px;
  }
  .r-active {
  background: #fe4d3c;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.5px;
}
.r-inactive {
  background: #f6f8f9;
  border-radius: 5px;
  color: #667499;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.5px;
}
  </style>
